@import "../../../../stylesheets/variables.scss";
@import "../../../../stylesheets/responsive.scss";
@import "../../../../stylesheets/common-style.scss";

.bottomNav {
    display: none;
    height: 0px;
    @media #{$mobile_device} {
        display: flex;
        align-items: center;
        margin: 3px;
        justify-content: space-between;
    }
}

.bottomNavItem{
    width: 24%;
    text-align: center;
    border-radius: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.bottomNavItem:hover{
    background: $tag-blue;
    color: $white;
}

.selectedBottomNavItem{
    background: $tag-blue;
    color: $white;
}