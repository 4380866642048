.overall-analysis-container {
    padding-top: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    margin: auto;
  }
  
  .summary-blocks {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
  }
  
  .summary-block {
    background-color: #ffffff;
    flex: 1;
    padding: 15px;
    text-align: center;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
  .summary-block h2 {
    font-size: 1.5rem;
    margin-bottom: 5px;
    color: #333;
  }
  
  .summary-block p {
    font-size: 0.9rem;
    color: #666;
  }
  
  .details-row {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .details-block {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    max-height: 100%;
    overflow-y: auto;
  }
  
  .details-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .details-table th,
  .details-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    color: #333;
  }
  
  .details-field {
    font-weight: bold;
    width: 40%;
  }
  
  .details-value {
    width: 60%;
  }
  
  .charts-row {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .chart-block {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  