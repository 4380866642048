@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets/responsive.scss";
@import "../../../stylesheets/common-style.scss";

.chat-bot-container {
  width: 100%;
  height: 100%;
  margin-top: -3.5%;
  overflow: auto;
}

/* ChatBot container */

.chatbot {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Hide the scrollbar initially */
  position: relative;
  /* Create a positioning context for the content */
  border-radius: 12px;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.chatbot::-webkit-scrollbar {
  width: 0;
  /* Hide the scrollbar on Webkit browsers (Chrome, Safari, etc.) */
}

.live-chat {
  // max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  // margin-bottom: 5%;
  /*border: 1px black solid;*/
  max-width: 98.5%;
  padding: 8px;
  /*padding-bottom: 10% !important;*/
}

.live-chat::-webkit-scrollbar {
  width: 0;
  /* Hide the scrollbar on Webkit browsers (Chrome, Safari, etc.) */
}

@keyframes scroll-up {
  0% {
    top: 100%;
    /* Start scrolling from the bottom */
  }

  100% {
    top: -100%;
    /* Scroll upwards */
  }
}

/* Header styling */

.chatbot-header {
  align-items: center;
  display: flex;
  justify-content: center;
  /*background-color: #7169e8;*/
  background-color: #555;
  color: white;
  position: relative;
  height: 4.5vh;
  /*border-radius: 20px 20px 0 0;*/
}

.avatars {
  width: 28px;
  /* Adjust the width and height as needed */
  height: 28px;
  padding: 0px;
  background-color: black;
  border-radius: 50%;
  /* This makes it circular */
  overflow: hidden;
  /* Hides image overflow */
  box-shadow: 0 0 3px #ffffff;
  /* Optional shadow effect */
  margin: 3% 1.2% 3% 0%;
}

.avatars img {
  width: 100%;
  /* Make sure the image covers the circular div */
  height: 100%;
  object-fit: cover;
  /* Maintain image aspect ratio */
}

.bot-inActive {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: red;
  left: 20px;
  bottom: 15px;
  position: absolute;
  animation: blink 0.7s infinite alternate;
}

.bot-active {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: green;
  left: 20px;
  bottom: 15px;
  position: absolute;
  animation: blink 0.7s infinite alternate;
}

@keyframes blink {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

h3 {
  height: 14px !important;
  margin-top: -0%;
}

.bot-image-text-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.chatbot-messages {
  position: relative;
  display: grid;
}

.chatbot-footer {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  color: #000000;
  display: inline-block;
  width: 100%;
  padding: 4px;
  //position: fixed;
  bottom: 0%;
  right: 0%;
  border-radius: 0 0 12px 12px;
}

.bot-image-container {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-bottom: -0.5%;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000 !important;

  @media screen and (min-width: 760px) {
    width: 40px;
    height: 40px;
  }
}

.bot-image {
  width: 20px;
  height: 20px;
  padding: 0;

  @media screen and (min-width: 760px) {
    width: 30px;
    height: 30px;
  }
}

/* Segment selection buttons */
.segment-buttons {
  display: flex;
  border-bottom: 1px solid lightgray;
  padding-bottom: 2.5%;
  justify-content: center;
  padding-top: 2.5%;
  width: 100%;
  cursor: pointer;
}

.button {
  background-color: #555;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 8px 18px;
  margin: 0 8px;
  cursor: pointer;
}

.button.active {
  background-color: #7169e8;
}

small {
  font-size: 13px !important;
}

/* Contact Us styling */
.contact-us {
  padding: 10px;
  margin-top: 5%;
  text-align: left;
  margin-left: 1.5%;
}

/* Form styling */
form {
  display: flex;
  flex-direction: column;
  width: 92%;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

/* Chat Input */
.chat-bot-input-container {
  display: flex;
  padding: 1px;
  flex-direction: inherit;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid lightgray;
  // min-width: 98.5%;
  width: 77%;
  height: 5.3vh;
  min-height: 50px;
  position: absolute;
  bottom: 4%;
  resize: none;
  transition: border-color 0.3s;
}

.chat-bot-input-container.focused {
  border-color: #7169e8;
}

.chat-bot-input {
  flex: 1;
  padding: 10px 3px 10px 12px;
  border-radius: 8px;
  outline: none !important;
  border: none !important;
  /*box-shadow: none !important;*/
}

input[type="text"]::placeholder {
  color: #ccc;
}

.send-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #7169e8;
}

.arrow-icon {
  width: 25px;
  /* Adjust the size as needed */
  height: 25px;
  /* Adjust the size as needed */
  background-image: url('../../../assets/images/bot/paper.png');
  background-size: cover;
  color: #7169e8;
}

.contact-input {
  width: 230px;
  border-radius: 8px;
  /*font-size: 16px;*/
  /*font-weight: 500;*/
  height: 25px;
  margin-top: 3%;
}

input[type="email"],
input[type="tel"],

textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-top: 3%;
}

.submit {
  background-color: #7169e8;
  color: white;
  border-radius: 8px;
  margin-top: 2%;
  width: 106%;
  padding: 12px;
  cursor: pointer;
}

/* Message styling */
.message {
  padding: 6px;
  border-radius: 8px;
  margin-bottom: 10px;
  max-width: 80%;
  align-items: center;
  margin-bottom: 8px;
  animation: scroll 10s linear;
  /* Adjust the duration as needed */
  animation-fill-mode: forwards;
  /* Stop at the end of the animation */
}

.user-message {
  background-color: #7169e8;
  max-width: 85%;
  float: right;
  width: fit-content;
  min-height: 2.5vh;
  height: auto;
  margin-top: 10px;
  margin-left: 13%;
  font-size: 14px;
  font-weight: 700;
  word-break: break-word;
  color: #ffffff;
  overflow: hidden;
  /* Hide text that overflows */
  // box-shadow: 0 4px 7px darkgray;
  text-align: justify;
  padding-left: 1.6%;
  padding-right: 1.6%;
  /*text-overflow: ellipsis; !* Display an ellipsis (...) for overflowed text *!*/
}

.bot-message {
  // background-color: #FAFAFA;
  max-width: 84%;
  width: fit-content;
  min-height: 2.5vh;
  height: auto;
  margin-left: 1%;
  margin-bottom: 20px;
  // margin-top: 1.7%;
  font-weight: 700;
  font-size: 14px;
  padding-left: 1.6%;
  padding-right: 1.6%;
  word-break: break-word;
  overflow: hidden;
  /* Hide text that overflows */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) for overflowed text */
  float: left;
  text-align: left;
  padding-top: 0;

  @media screen and (min-width: 760px) {
    font-size: 16px;
  }
}

.bot-message-date {
  float: left;
  font-size: 10px;
  margin-left: 5%;
  width: 12%;
  text-align: left;
}

.user-message-date {
  float: right;
  font-size: 10px;
  position: relative;
  width: 8%;
  margin-left: 75%;
  text-align: right;
}

/*.bot-message:first-child {*/
/*    margin-top: 0%;*/
/*    margin-bottom: 0;*/
/*}*/

/*.bot-message:not(:first-child) {*/
/*    margin-top: 3%;*/
/*}*/

.product-grid {
  display: grid;
  /*grid-template-columns: repeat(2, 1fr); !* Two products per row *!*/
  gap: 8px;
  /* Adjust the gap between products as needed */
}

.product-card {
  border: 1px solid #ccc;
  padding: 1px 7px 8px 7px;
  border-radius: 14px;
  text-align: justify;
  height: auto;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.image-card {
  border: 1px solid #ccc;
  padding: 1px 7px 8px 7px;
  border-radius: 14px;
  text-align: justify;
  height: auto;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.image-card .product-image-container {
  margin-top: 1%;
  border-radius: 12px;
}

.image-card .product-image-container img {
  width: 600px;
  height: 400px;
  border-radius: 12px;
}

.download-button {
  padding: 10px;
  text-align: center;
  background: #007BFF;
  color: #fff;
  text-decoration: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(50% - 5px);
  /* Divide the width evenly between the buttons with spacing */
  margin: 15px 0 0 0;
  /* Add margin to separate the buttons */
}

.product-card img {
  width: 575px;
  height: 350px;
  margin-top: 10px;
  border-radius: 12px;
}

.product-card .product-image-container {
  margin-top: -0.5%;
}

.product-card h3 {
  font-size: 15px;
  margin: 1% 1% 4% 1%;
  font-weight: bold;
  height: auto;
  min-height: 1vh;
  text-align: center;
}

.product-card h4 {
  font-size: 15px;
  font-weight: bold;
  min-height: 0.5vh;
  height: auto;
  text-align: center;
}

.product-card p {
  font-size: 13px;
  min-height: 1vh;
  padding: 6px 6px 0px 6px;
  margin-top: -1%;
  height: auto;
  margin-bottom: 0%;
}


.product-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-button {
  flex: 1;
  padding: 10px;
  text-align: center;
  background: #007BFF;
  color: #fff;
  text-decoration: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: calc(50% - 5px);
  /* Divide the width evenly between the buttons with spacing */
  margin: 10px 0 0 0;
  /* Add margin to separate the buttons */
}

.product-button.checkout {
  background: #555;
  /* Customize the Checkout button's background color */
}

.product-button:hover {
  background: #0056b3;
  /* Change the background color on hover */
  color: #ffffff;
}

.loading-container {
  text-align: center;
  margin: 5% !important;
}

.loading-icon {
  color: #7169e8;
  font-size: 2rem;
  animation: animate 2s infinite;
  display: inline-block;
  margin-left: 8%;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(720deg);
  }
}

.btn-secondary :hover {
  background-color: #FFFFFF !important;
}

.analysis-wrapper {
  &__title {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 60px;
    font-size: 30px;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__card {
    margin-right: 10px;
    cursor: pointer;
    // width: 350px;
    height: 150px;

    &:hover {
      // box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3);
      border: 1px solid #cccccc;
    }

    h2 {
      font-size: 14px;
      font-weight: 700;
      text-align: center;
    }
  }

  .card-img {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    img {
      height: 100%;
    }
  }
}

.left-panel {
  display: none !important;

  @media screen and (min-width: 760px) {
    display: flex !important;
  }

  &__header{
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #d8d6de;
    vertical-align: middle;
    padding: 15px 20px;
    font-size: 18px;
    font-weight: 700;
    height: 60px;
  }
  &__body{
    padding: 20px;

  }
}

.right-panel {
  width: 100% ;
  padding: 10px !important;
  height: 100%;
  overflow: hidden;
  @media screen and (min-width: 760px) {
    width: 80%;
    padding: 20px !important;
  }

  .analysis-wrapper {
    display: none;

    @media screen and (min-width: 760px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
    }
  }

  .chat-bot-input-container {
    width: calc(100% - 20px) !important;

    @media screen and (min-width: 760px) {
      width: 100% !important;
    }
  }
}

.main-container {
  width: 100% !important;
  height: calc(100vh - 210px) !important;
  flex-wrap: wrap;
  @media screen and (min-width: 760px) {
    height: calc(100vh - 5.35rem) !important;
  }
}

.app-content {
  padding-inline: 0 !important;

  @media screen and (min-width: 760px) {
    padding-inline: 2rem !important;
  }
}

.document-intelligence {
  margin-left: -1rem;
  margin-right: -1rem;
  width: calc(100% + 2rem);

  @media screen and (min-width: 760px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

.mobile-bar{
  display: none;
  @media #{$mobile_device} {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: space-between;
    padding: 8px;
    background-color: #0c6980;

    .document-list{
      padding: 10px 12px;
      margin-bottom: 0;
      color: #fff;
    }
  }
}

.chatbot-section{
  background-color: #fff;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative;

  &__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(216, 214, 222);
    margin-bottom: 20px;
    height: 60px;

    h2{
      padding-top: 20px;
    }
  }
  &__btn-wrapper{
    display: flex;

    button{
      line-height: 1 !important;
    }
  }
}

.chat-pannel{
  height: calc(100% - 80px) !important;
  padding-bottom: 50px;
  overflow-y: scroll;
}
.chat-bot-input-wrapper{
  display: block;
  // width: 100%;
  position: absolute;
  left: 20px;
  right: 20px;
  z-index: 9;
}

.document-item{
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  &__file-name{
    white-space: nowrap;        /* Prevent text from wrapping */
    overflow: hidden;           /* Hide the overflowing text */
    text-overflow: ellipsis; 
  }
  &__delete-btn{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: #fff;
    border: unset;
    color: red;
    font-size: 20px;
    font-weight: 800;
    transition: all 0.3s ease;

  }
}

.document-confirmation-modal{
  &.modal-dialog {
    margin: auto;
    top: 50%;
    transform: translateY(-50%) !important;
  }
}