.response-actions {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__cta {
        display: flex;
        justify-content: flex-end;

        .btn {
            padding: 6px 12px;
            font-size: 16px;
            font-weight: 700;
            margin-right: 5px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    &__feedback {
        .btn {
            padding: 6px 12px;
            font-size: 16px;
            font-weight: 700;
            margin-right: 5px;

            svg {
                width: 16px;
                height: 16px;
            }

            &.active{
                svg{
                    fill: #44ace4;
                }
            }
        }
    }
}