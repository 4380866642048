.product-plans {
    padding-block: 20px;

    &__header-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__header {
        h2 {
            color: #000;
            font-weight: 600;
        }
    }

    .section-divider {
        border-top: 1px solid #ddd;
        margin: 20px 0;
    }

    .plans-info {
        padding-bottom: 20px;
        width: 100%;

        &__detail {
            // width: 50%;
            // margin-bottom: 40px;
            border-collapse: collapse;

            td {
                padding: 10px;
                font-size: 14px;

                &:first-child {
                    font-weight: 600;
                }

                span {
                    color: #000;
                    font-weight: 600;
                }
            }

            .progress {
                margin-top: 10px;

                .progress-bar {
                    height: 100%;
                }
            }
        }
    }

    .validation-toggle__wrapper {
        display: flex;
        justify-content: left;
        margin-bottom: 1.5rem;
    }

    .toggle-tab {
        padding: 10px 15px !important;
        font-size: 12px;
        font-weight: 600;
        border-radius: 25px;
        border-color: #000 !important;

        &.active {
            background-color: #000 !important;
            color: white !important;
        }

        &.btn-outline-primary {
            color: #000;

            &:hover {
                color: #fff;
                background-color: #000;
            }
        }
    }

    .pricing-header {
        margin-top: 20px;
        font-weight: 600;
        // text-align: center;
        color: #000;
    }

    .plan-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        // max-width: 1000px;
        margin-inline: auto;
    }
}

.history-wrapper {
    margin-top: 30px;
    &__list {
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;

            padding-block: 15px;

            &:not(:first-of-type){
                border-top: unset;

            }

        }
    }

    &__list-info {
        display: flex;
        align-items: center;

        img {
            width: 30px;
            height: 30px;
            margin-inline: 10px 20px;
        }

        span {
            display: inline-block;
            color: #000;
            font-size: 16px;
            font-weight: 600;
        }
    }

    &__date {
        display: inline-block;
        color: #000;
        font-size: 16px;
        font-weight: 600;
        margin-inline:15px;
    }
}