.auth-wrapper {
    &.verification {
        .auth-inner {
            max-width: 600px !important;

            .card {
                width: 100% !important;
            }

            h4 {
                font-size: 28px;
            }
        }
    }

}