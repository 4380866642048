@import "../../stylesheets/responsive.scss";

//layout-wrapper
.layout-wrapper{
    overflow-x: hidden;
    overflow-y: scroll;
}
.footerBottomNav{
    display: none;
    @media #{$mobile_device} {
        display: block;
    }
}

.loader-wrapper{
    margin: auto;
    height: 60vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-nav-btn{
    padding: 12px;
    display: block;
    color: #4B5563;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    border-radius: 12px;
    margin-bottom: 12px;

    &:hover{
        background-color: #0C6980;
        color: white;
    }

    &.active{
        background-color: #0C6980;
        color: white;
    }
}
.main-menu{
    &:hover{

    }
}
.menu-collapsed{
    .navigation-main{
        padding-inline:12px !important;
    }
    .main-menu .navbar-header{
        padding-left: 1rem !important;
    }
}
.main-menu .navbar-header .navbar-brand{
    // margin-top: 0 !important;
}
.brand-text{
    color: #111827 !important;
    font-weight: 700 !important;
    padding-left: 5px !important;
    span{
        color: #28C29C !important;
    }
}
.navigation-main{
    padding-inline:24px !important;
    .nav-item{
        a{
            padding: 12px !important;
            display: block;
            color: #4B5563;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            letter-spacing: 0.14px;
            border-radius: 12px;
            margin-bottom: 12px !important;
        
            &:hover{
                background-color: #0C6980;
                color: white !important;
            }
            &.active-button{
                background-color: #0C6980;
                color: white !important;
            }
        }
    }
}

.custom-nav{
    display: block !important;
    visibility:visible !important;
}