.api-dashboard{
    .card-header{
        padding-inline: 0;
        padding-top: 0;
    }
    .card-action-wrapper{
        display: flex;

        button{
            padding: 0;
            margin-right: 15px;
            font-size: 20px;
        }
    }
    .card-image{
        width: 100%;
        height: 200px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}