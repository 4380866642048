// Modal styles
.modal-content {
    border-radius: 10px;
  }
  
  .modal-header {
    background-color: #007bff;
    color: white;
  }
  
  .modal-body {
    padding: 20px;
  }
  
  .modal-footer {
    background-color: #f9f9f9;
    border-top: none;
  }
  
  // Checkbox styles
  .parameter-container {
    margin-bottom: 10px;
  }
  
  .parameter-container label {
    display: inline-block;
    margin-left: 10px;
    font-size: 16px;
  }
  
  .parameter-container input[type="checkbox"] {
    margin-right: 10px;
    transform: scale(1.5);
  }
  
  // Headings styles
  .custom-heading {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    color: #007bff;
  }
  