.conversation-wrapper {
    display: flex;
    // border-radius: 16px;
    border: 1px solid #ECEDF3;
    background: #FFF;
    height: 65vh;
    overflow: hidden;

    &__top{
        height: 50px;
        // border-bottom: 1px solid #0C6980;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #0C6980;
    }

    &__left {
        width: 30%;
        border-right:1px solid #ECEDF3;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
    }

    &__right {
        width: 70%;
        padding: 20px;
    }

    .document-list{
        height: calc(100% - 140px);
        margin-bottom: 0;
        padding: 20px;
    }

    &__bottom{
        // height: 90px;

        .upload-btn{
            width: 100%;
        }
    }

    .nav-link {
        border-bottom: 1px solid #D2D5DB;
        color:  #6B7280;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        padding: 16px 20px;

        &.active{
            background:#0C6980;
            color: #FFF;

            &::after{
                display: none;
            }
        }
    }

    &__tabcontent{
        height: 100%;
    }

    &__tabpane{
        height: 100%;
        position: relative;
        padding-bottom: 50px;
    }

    .conversation-pane{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        form{
            width: 100% !important;
            position: relative;

        }

            input{
                padding-right: 50px;
            }

            .icon{
                position: absolute;
                z-index: 1;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                display: block;
                width: 20px;
                height: 20px;
                padding: 0;
                border: unset;
                background-color: transparent;
                color: #0C6980;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
    }
}

.document-list-item{
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
}

.document-list-item:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.cancel-btn{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    padding: 0;
    color: red;

    svg{
        width: 15px;
        height: 15px;
    }
}

// Advanced search toggle
.advanced_toggle{
    position: absolute;
    right: 20px;
    top: 20px;
    // width: 250px;
    height: 50px;
    color: #0C6980;
    font-size: 18px;
    text-align: end;
}
