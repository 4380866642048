/* ContactUs.css */
.contact-us {
  padding: 20px 10px !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  &__title{
    color: #000000;
    width: 100%;
    margin-bottom: 0;
    padding-inline: 30px;
    padding-bottom: 20px;
    font-weight: 600;
    border-bottom: 1px solid #dbdade;
  }

  .row{
    padding: 30px;
  }

  &__text-box{
    position: relative;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding: 30px 30px 125px;
    width: 60%;

    img{
      height: 100%;
      object-fit: cover;
    }
  }
  .support-info{
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.support-faq{
  height: 60vh;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */

  /* Webkit Browsers (Chrome, Safari, etc.) */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Webkit browsers */
  }


}

.landing-contact .contact-img-box::before {
  position: absolute;
  inset-block-start: -2.5rem;
  inset-inline-start: -2.8125rem;
}

.col-lg-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}


.h2 {
  font-size: 27px;
}

.p {
  font-size: 17px;
  width: 55%;
  display: inline-block;
  text-align: center;
  margin-top: 0.5%;
}

.p-2 {
  padding: 0.5rem !important;
}

.h-100 {
  height: 100% !important;
}

.border {
  border: 1px solid #dbdade !important;
}

.col-lg-5 {
   flex: 0 0 auto;
   width: 41.66666667%;
}

.contact-info h2 {
  font-size: 36px;
}

.contact-info p {
  font-size: 18px;
  margin-bottom: 30px;
}

.contact-list {
  list-style: none;
  padding: 0;
}

.contact-list li {
  font-size: 18px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.contact-list i {
  font-size: 24px;
  margin-right: 20px;
}

.contact-form {
  width: 40%;
  // background-color: #0C6980;
  padding: 40px !important;
  border-right:1px solid #dbdade;

  form{
    width: 100%;

    .btn{
      span{
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
  &__inputs{
    margin-bottom: 1rem !important;

    // label{
    //   color: #ffffff;
    // }
    // input, textarea{
    //   background-color: #ffffff2e !important;
    //   border-color: #ffffff !important;
    //   color: #ffffff !important;
    // }
    // select{
    //   background-color: #ffffff2e !important;
    //   border-color: #ffffff !important;
    //   color: #ffffff !important;

    //   option{
    //     color: #6e6b7b !important;
    //   }
    // }
  }
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 1.55rem !important;
}

.contact-form .form-control {
  font-size: 15px;
}

.contact-form textarea {
  resize: none;
}