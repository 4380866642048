/* KYCInfo.css */
  
  .kyc-card {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 10px;
    transition: transform 0.3s ease;
  }
  
  .kyc-card:hover {
    transform: translateY(-5px);
  }
  
  .kyc-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .kyc-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .kyc-table tr {
    border-bottom: 1px solid #e0e0e0;
  }
  
  .kyc-field {
    font-weight: bold;
    padding: 12px;
    background-color: #f8f8f8;
    color: #666;
    width: 40%;
  }
  
  .kyc-value {
    padding: 12px;
    color: #333;
    text-align: left;
  }
  
  .kyc-table tr:last-child .kyc-field,
  .kyc-table tr:last-child .kyc-value {
    border-bottom: none;
  }
  