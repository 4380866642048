.wtsApp-bot{
    .breadCrumb-bar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
    .name{
        font-size: 24px;
        font-weight: 600;
        color: #000000;
        line-height: 1.1;
    }
    .name-title{
        display: inline-block;
        font-weight: 500;
        font-size: 18px;
    }
    .number{
        line-height: 1.1;
        color: #000000;
        font-size: 24px;
        font-weight: 600;
       
    }
}

.wtsapp-table{
    th,td{
        text-align: center;
    }
    thead{
        tr{
            &::after{
                display: none;
            }
        }
    }

    .table-success{
        position: relative;

        &::after{
            content: 'Premium';
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            background-color: #FFF59D;
            color: #FF5722;
            padding: 2px 6px;
            font-size: 10px;
            font-weight: 700;
            line-height: 1;
        }
    }
}