.forecast-tab{
    margin-top: 30px;

    &__graph{
        margin-bottom: 30px;
    }
}

.simulation-modal{
    .modal-body{
        padding-inline: 30px !important;
    }
}