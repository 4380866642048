.container {
  width: 620px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-bar-container {
  display: flex;
  align-items: center;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.circle.active {
  background-color: #4CAF50;
}

.circle i {
  color: white;
}

.progress-bar {
  width: 200px;
  /* Full width of the bar */
  height: 10px;
  background-color: gray;
  // margin: 0 20px;
  overflow: hidden;
}

.progress-bar::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #4CAF50;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 1.2s ease;
}

.progress-bar.filled::after {
  transform: scaleX(1);
}

.labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.label {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.large-icon {
  font-size: 20px;
}

.analysis-status {
  margin-top: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  .custom-check {
    border-radius: 50%;
  }

  &__item {
    display: flex;
    align-items: center;
    opacity: 0;
    transform: translateY(-20px);
    /* Animation will make it "slide in" */
    transition: opacity 0.5s ease, transform 0.5s ease;
    /* Smooth transition */
    margin-bottom: 10px;

    &.fade-in {
      opacity: 1;
      transform: translateY(0);
    }

    &.hidden {
      display: none;
      /* Completely hide the checkbox until it's checked */
    }
  }

  .round {
    position: relative;
    margin-right: 10px;

  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 7px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
    height: 24px;
    width: 24px;
  }

  .round input[type="checkbox"]:checked+label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  .round input[type="checkbox"]:checked+label:after {
    opacity: 1;
  }

  span {
    font-size: 20px;
    color: #000000;
  }
}