.db-itelligence {
    display: flex;
    height: 100%;

    &__sidebar {
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-block: 20px;

        .btn {
            font-weight: 600;
            font-size: 16px;
        }
    }

    &__sidebar-title {
        color: white;
        background-color: #0C6980;
        font-size: 1.2rem;
        padding: 14px 16px;
        text-align: center;
        font-weight: 700;
        height: 53px;
    }

    &__sidebar-body {
        height: calc(100% - 93px);
        padding: 20px 10px;
        text-align: center;
        vertical-align: middle;
        overflow: scroll;


        &.without-schema {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__sidebar-bottom {
        height: 40px;

        .btn {
            width: 100%;
        }
    }

    &__body {
        width: 100%;
        padding: 20px;
    }

    &__db-selection {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        padding-top: 20px;
        height: calc(85vh - 93px);
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.db-card {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid rgba(34, 41, 47, 0.1);
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    border-radius: 12px;
    max-height: 200px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    height: 110px;

    &__img {
        width: 40%;
        height: 70px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        width: 60%;
        font-size: 16px;
        padding-left: 20px;
    }

    &__premium-tag {
        position: absolute;
        top: 12px;
        right: -25px;
        width: 110px;
        text-align: center;
        padding: 4px 12px;
        background-color: #A5D6A7;
        color: #009688;
        font-weight: 700;
        font-size: 10px;
        transform: rotate(40deg);
    }

    &.db-card--premium {
        pointer-events: none;
    }
}

.db-stepper {
    height: 53px;
}

.db-form {
    padding-top: 20px;
    height: calc(75vh - 93px);
    overflow: scroll;
    display: flex;
    justify-content: center;

    &::-webkit-scrollbar {
        display: none;
    }

    form {
        width: 30%;
    }

    h2 {
        text-align: center;
        margin-bottom: 20px;
    }

    .btn-wrapper {
        display: flex;
        justify-content: space-between;

        .btn {
            font-weight: 600;
            font-size: 16px;
        }
    }
}

.db-connect {
    padding-top: 20px;
    height: calc(85vh - 93px);

    &__tabpane {
        height: 100%;
        position: relative;
        padding-bottom: 50px;
    }

    .conversation-pane {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        form {
            width: 100% !important;
            position: relative;

        }

        input {
            padding-right: 50px;
        }

        .icon {
            position: absolute;
            z-index: 1;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            width: 20px;
            height: 20px;
            padding: 0;
            border: unset;
            background-color: transparent;
            color: #0C6980;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.db-intelligience-chat {
    .left-panel {
        .db-accordion {
            height: calc(100% - 73px);
            padding: 20px 10px;
            text-align: left;
            vertical-align: middle;
            overflow: scroll;

        }
    }

    .main-container {
        height: calc(100vh - 10.8rem) ;
        flex-wrap: nowrap !important;
    }

    .accordion-body {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.loading-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.connect-header {
    line-height: 53px;
    font-size: 23px;
    font-weight: 600;
    color: green;
    border-bottom: 1px solid #0C6980;

    &.withId {
        color: #0C6980;
    }
}

.db-accordion {
    &__first {
        button {
            font-size: 18px !important;
            font-weight: 600 !important;

            &::after {
                display: none !important;
            }
        }
    }

    .accordion-item {
        border-bottom: unset !important;
    }

    &__second {
        button {
            font-size: 16px !important;
            font-weight: 600 !important;

            &::after {
                display: none !important;
            }
        }
    }
}

.sectionHeaderBtn {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -10px;
    font-size: 14px;
    font-weight: bold;
    padding-top: 10px;
    height: 40px;
    border-radius: 5px;
    background: #90CAF9;
    color: #283593;
    cursor: pointer;
}

.sectionHeaderBtn:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

// Analytics Floating Progress
.analytics-floating-progress {
    position: fixed;
    height: 60%;
    width: 280px;
    bottom: 15%;
    right: 3%;
    z-index: 14;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background: #FFFFFF;
    color: white;
    border-radius: 10px;
    transition: all 0.3s ease;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        color: #546E7A;
        border-bottom: 1px solid #CCCCCC;
        font-size: 15px;
    }

    &__query_container {
        max-height: 80%;
        width: 100%;
        color: #455A64;

        .header {
            margin-top: 10px;
            font-size: 18px;
            font-weight: bold;
            color: #0C6980;
        }

        .analytics_db_query_container {
            margin-top: 10px;
            padding: 5px;
            height: 35px;
            border: 1px solid #CCCCCC;
            border-radius: 3px;
            background: #FFFFFF;
            max-height: 100%;
            display: flex;
            justify-content: space-between;
        }

        .query_added {
            border-bottom: 3px solid #388E3C;
            color: #388E3C;
            font-weight: bold;
        }
    }

    .redirect_to_dashboard {
        font-weight: 600;
        cursor: pointer;
        background-color: unset;
        border: none;
        padding: 0;
        color: #546E7A;

        &:disabled{
            pointer-events: none;
            cursor: no-drop;
        }
    }

    .redirect_to_dashboard:hover {
        text-decoration: underline;
    }

    .close_popup {
        cursor: pointer;
        color: #546E7A;
        margin-left: 10px;
    }
}

.minimized {
    height: 50px;
}

.minimized_header {
    border: 0px;
}

/* Keyframes for slide-in animation from the right */
@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Apply the animation to the div */
.slide-in-right {
    animation: slideInFromRight 1s ease-out;
}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Apply the animation to the div */
.fade-in {
    animation: fadeIn 1s ease-in;
}

// sampleDB-modal
.sampleDB-modal {
    width: 100% !important;
    max-width: 1100px !important;
    margin-block: calc(50vh - (511px / 2)) !important;

    .modal-body {
        height: 400px;
        padding-inline:30px !important;
        overflow: scroll;
    }

}

.sampleDBList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding-top: 20px;
}

.sampleDB-card {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid rgba(34, 41, 47, 0.1);
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    border-radius: 12px;
    max-height: 200px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    height: 110px;

    &:hover {
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3);

    }

    &.active{
        box-shadow: 0 4px 24px 0 rgba(56, 142, 60, 0.3);
        border-color: #388E3C;
    }

    &__img {
        width: 30%;
        height: 55px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__title {
        width: 70%;
        font-size: 16px;
        font-weight: 700;
        padding-left: 10px;
    }
}