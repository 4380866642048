.recommended-blogs {
    margin-bottom: 32px;

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
    }
}

.custome-subtitle {
    color:  #111827;
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.18px;
    margin-bottom: 12px;
}

.recommend-blog-card {
    padding: 16px;
    border-radius: 16px;
    border: 1px solid #ECEDF3;
    box-shadow: none !important;

    &:nth-child(1){
        background: linear-gradient(0deg, rgba(226, 144, 255, 0.20) 0%, rgba(226, 144, 255, 0.20) 100%), #FFF;
    }
    
    &:nth-child(2){
        background: linear-gradient(0deg, rgba(153, 144, 255, 0.20) 0%, rgba(153, 144, 255, 0.20) 100%), var(--White, #FFF);
    }
    
    &:nth-child(3){
        background: linear-gradient(0deg, rgba(144, 228, 255, 0.20) 0%, rgba(144, 228, 255, 0.20) 100%), var(--White, #FFF);
    }
    h2 {
        color: #111827;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
    }

    p {
        overflow: hidden;
        color: var(--Neutral-700, #374151);
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.12px;
    }

    .reading-time {
        overflow: hidden;
        color: #6B7280;
        text-overflow: ellipsis;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
        letter-spacing: 0.12px;
    }
}