@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets/responsive.scss";
@import "../../../stylesheets/common-style.scss";

/* Add this to your CSS file */
.fixed-header-table-container {
    overflow-y: auto;
    height: calc(100vh - 70px); /* Adjust height as needed */
}

.fixed-header-table thead th {
    position: sticky;
    top: 0;
    background-color: darkgrey;
    color: white;
}

.fixed-header-table {
    width: 100%;
    border-collapse: collapse; /* This is necessary for border styling */
}