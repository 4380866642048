.outer-template-container{
    width: 100%;
    padding: 15px;
    min-height: 20px;
    // background-color: #f5f5f5;
}

.template-container-header{
    font-size: 16px;
    font-weight: bold;
}

.template-container-items-list{
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    // flex-wrap: wrap;
    // justify-content: start;
}

.template-container-item{
    position: relative;
    min-height: 210px;
    // width: 250px;
    // margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #B0BEC5;
    cursor: pointer;
    transition: transform 0.3s;
}
.template-container-item:hover{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.template-container-item .image-container{
    height: 90px;
    width: 100%;
    padding: 15px;
    text-align: center;
}

.template-container-item .image-container .image-item{
    max-height: 90%;
}

.template-container-item .header{
    height: 40px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}

.template-container-item .description{
    height: 110px;
    width: 100%;
    text-align: center;
    padding-left: 10px;
    padding-bottom: 40px;
    padding-right: 10px;
    font-size: 13px;
}

// Overlay on divs
.overlay {
    position: absolute;
    // display: none;
    bottom: 0; /* Initially positioned at the bottom of the parent div */
    left: 0;
    width: 100%;
    height: 0px;
    background: #039BE5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    transition: height 0.3s ease;
}

.template-container-item:hover .overlay {
    // display: block; /* Slide up the overlay on hover */
    height: 50px;
}

.overlay .action-btn {
    height: 100%;
    width: 50%;
    background-color: #039BE5;
    color: #fff;
    border: none;
    cursor: pointer;

    img{
        width: 16px;
        height: 16px;
        margin-right: 6px;
    }
}

.ledger-modal{
    .modal-body{
        padding: 30px 20px 10px;
    }

    form{
        width: 100% !important;
    }
}

/* Multi file uplaod */
.uploaded-files-list {
    margin-top: 20px;
    padding: 10px;
    border-top: 1px solid #ddd;
  }
  
  .uploaded-file-item {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .uploaded-file-item svg {
    margin-right: 10px;
  }
  
  .remove-file-button {
    background: none;
    border: none;
    color: #ff4d4f;
    cursor: pointer;
  }
  
  .remove-file-button:hover {
    color: #ff7875;
  }

  .multi-file-upload-modal{
    padding: 10px;
  }