.o_topNavbar{
    height: 110px;
    padding: 33px 30px 32px 34px;
}
.o_heading{
    display:flex;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
}
.o_heading h2{
    font-size: 32px;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
}
.o_searchBar{
    display: flex;
    border: 1px solid #d9d9d9;
    height: 33px;
    width: 420px;
    max-width: 100%;
    border-radius:2px;
    padding: 4px 0 4px 11px;
}
.o_searchBar img{
    width:14px;
    height: 14px;
    margin: auto;
}
.o_searchBar input{
    border:0;
    outline:0;
    height: 23px;
    width: 389px;
    font-size: 16px;
    margin: auto;
}
.o_searchBar input::placeholder{
    color: #d9d9d9;
}
.o_content{
    height: 100vh;
    display:"flex";
}
.o_card01{
    background-color: #fff;
    border-radius: 3px;
    box-shadow: rgba(68, 90, 116, 0.25) 0px 1px 4px 0px;
    height: 180px;
    /* width: 230px; */
    padding: 18px 18px 12px 18px;
    /* margin: 0 10px 10px 0; */
    cursor: pointer;
    float: left;
}
.o_card01:hover{
    background-color: #fffdf5;
    outline: 1px solid #febd00;
}
.o_card01 img{
    width: 32px;
    height: 32px;
}
.o_card01 .o_heading{
    font-size: 15px;
    line-height: 21px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
    margin: 12px 0 0;
}
.o_card01 .o_text{
    font-family: Arial, Helvetica, sans-serif;
    margin: 18px 0 12px 0;
    color: #00182E8C;
    font-size: 15px;
    height: 100px;
}
.o_card_container{
    display: flex;
    flex-wrap: wrap;
}
.o_tab_container{
    margin-top: 30px;
    height: 48px;
    padding: 0 30px 0 32px;
}
.o_tab{
    color: black;
    margin-right: 15px;
    padding: 0 20px 18px 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.api-card-column{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}