.history-table {
    border: 1px solid #ebe9f1 !important;
    border-radius: 10px;
    overflow: hidden;
    background-color: white;


    thead {
        th {
            background-color: #f9faff !important;
            text-transform: capitalize !important;
            font-size: 14px !important;
            color: #111827;
            font-weight: 600;
            line-height: 20px;
            padding: 12px 24px !important;

        }
    }

    .rdt_TableHead {
        // padding: 12px 24px !important;

        .rdt_TableHeadRow {
            background-color: #f9faff !important;
            text-transform: capitalize !important;
            font-size: 14px !important;
            color: #111827;
            font-weight: 600;
            line-height: 20px;
            padding: 12px 24px !important;
        }
    }

    .rdt_TableBody {
        .rdt_TableRow {
            font-size: 14px !important;
            color: #111827;
            font-weight: 600;
            line-height: 20px;
            padding: 16px 24px !important;
            cursor: default;

            &:hover {
                background-color: #0C6980;
                color: white;

                .btn-link{
                    color: white !important;
                }
            }

            .btn-link {
                color: #1C3FCA;
                font-weight: 100 !important;
                height: 20px;
                width: 20px;
                padding: 0 !important;
            }

        }
    }

    .bank-img {
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 4px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

}
.filter-wrapper{
    display: flex;
    justify-content: flex-end;
    .mb-3{
        margin-top: 16px !important;
        margin-bottom: 16px !important;
    }
}

.error-msg {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 30vh;
}