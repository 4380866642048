@import "../../stylesheets/variables.scss";
@import "../../stylesheets/responsive.scss";
@import "../../stylesheets/common-style.scss";

.dashboard-container {
  height: 84vh !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.modal-dialog.sidebar-lg {
  width: 50rem !important;
  height: 100% !important;

  .modal-title{
    position: relative;
    width: 100%;
    .btn{
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .sideform-wrapper{
    position: fixed;
    bottom: 3%;
    height: 40px;
    left: 10px;
    right: 10px;
    padding: 0 !important;

    form{
      width: 100%;
      height: 100%;
    }
    input{
      width: 100% !important;
      height: 100%;
    }
  }
  .icon{
    position: absolute;
    z-index: 1;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    color: #0C6980;
    transform: translateY(-50%);
    padding: 0;
    border: unset;
    background-color: transparent;
    right: 25px;

    svg{
      width: 100%;
      height: 100%;
    }
  }
}

.dashboardHeaderContainer {
  position: absolute;
  top: 0;
  height: 78px;
  background-color: white;
  left: 0;
  width: 100%;
  padding: 12px 16px;
}

.row {
  --bs-gutter-x: 1.3rem !important;

  .card-congratulation-text {
    width: 100%;
    font-size: 25px;
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 0%;
    font-weight: 700;
    color: #FFFFFF;
    font-family: -apple-system, sans-serif;
    line-height: 30.25px;
  }

  .group-image {
    position: absolute;
    bottom: -8%;
    right: 4%;
  }
}

.cardRoot {
  margin-top: 5%;
  width: 100%;
  align-items: center;
  text-align: center;
  z-index: 13;
  background-color: transparent;

  .documentSection {
    width: 98%;
    text-align: center;
    align-items: center;
    background-color: transparent;

    .documentImage {
      height: 100px;
      max-width: 100%;
      max-height: 100%;
      text-align: center;
    }

    .documentText {
      margin-top: 8%;
      border-radius: 6px;
      font-weight: 700;
      font-size: 18px;
      display: inline-block;
    }

    .documentTextParagraph {
      border-radius: 2px;
      margin-top: 1.5%;
      font-size: 12px;
      width: 75%;
      display: inline-block;
    }

    .documentButton {
      margin-top: 2%;
      display: inline-block;;
    }
  }
}

.documentModal {
  // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 7.45%, rgba(250, 249, 251, 0.94) 34.78%, #F7F6F9 54.58%);
  // width: 100%;
  // height: 100vh;
  // top: 8%;
  // position: absolute;
  // z-index: 11;
}

.dashboardHeaderIcon {
  padding: 2px;
  width: 24px;
  height: 24px;
}

.dashboardAlignRow {
  display: flex;
}

.dashboardAlignCloumn {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.dashboardAlignRow h1 {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 24px;
  color: #272829;
}

.dashboardAlignRow p {
  margin: 0px;
  padding-top: 2px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #5A5E66;
}

.insightContianer {
  width: 49%;
  height: 126px;
  background-color: #ffffff;
  border-radius: 5px;
}

.insightHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 12px 2px 12px;
}

.insightHeading h1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #272829;
  margin: 0px;
  padding-left: 12px;
  padding-right: 2px;
}

.insightIcon {
  width: 32px;
  height: 32px;
}

.dropdownToggle {
  background-color: #ffffff !important;
  color: #1565D8 !important;
  border: none !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding-right: 6px !important;
}

.dropdownToggle:hover {
  border: unset !important;
  color: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.dropdownToggle::after {
  color: #1565D8 !important;
}

.dropdownToggle:focus {
  background-color: unset !important;
}

.alignRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdownItem {
  width: 100% !important;
}

.actionIcon {
  padding-left: 8px;
}

.dropdownToggle:hover .actionIcon {
  stroke: rgb(110, 107, 123) !important;
}

.insightBodyContainer {
  padding: 0px 24px 26px 57px;
}

.insightBody h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #5A5E66;
  margin: 0px;
}

.insightBody h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #272829;
  margin: 0px;
  padding-top: 8px;
}

.campaignContainer {
  height: 269px;
  width: 32.5%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px;
}

.campaignImgStyle {
  height: 108px;
  width: 100%;
}

.campaignContainer h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #272829;
  margin: 0px;
  padding: 12px 0px 4px 0px;
}

.campaignContainer p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #5A5E66;
  margin: 0px;
}

.campaignBtn {
  background-color: #ffffff !important;
  border: 1px solid #3C29B7 !important;
  color: #3C29B7 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

}

.campaignBtn:hover {
  background-color: #ffffff !important;
}

.campaignBtn:focus {
  background-color: #ffffff !important;
}

.campaignDetailBtn {
  background-color: #ffffff !important;
  border: unset !important;
  color: #3C29B7 !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.campaignDetailBtn:hover {
  box-shadow: unset !important;
  background-color: #ffffff !important;
  opacity: 0.8;
}

.campaignDetailBtn:focus {
  background-color: #ffffff !important;
}

.trendingDashStyle h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #272829;
  margin: 0px;
  padding-left: 12px;

}

.trendingDashStyle p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: #000000;
  padding-left: 15px;
}

.trendingDashHeaderContainer {
  background-color: #ffffff;
  margin: 12px 0px;
  padding: 15px 12px;
}

.trendingDashContainer {
  height: 500px;
  background-color: #ffffff;
}

.dashboardContentContainer {
  height: calc(100% - 110px);
  overflow: auto;
  position: absolute;
  top: 100px;
  left: 0px;
  width: 98%;
  margin: 0px 12px;
}

.dashboardContentContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.notification-modal {
  margin: 35vh auto !important;
  position: relative;

  .btn-close {
    padding-top: 15px;
    padding-right: 20px;
  }

  .modal-content {
    align-items: flex-end;
  }

  .modal-body {
    min-height: 230px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    text-align: center;
    padding-inline: 30px !important;

    b{
      font-size: 22px;
      font-weight: 800;
    }
  }

  &__icon {
    width: 100px;
    height: 60px;
    margin-bottom: 40px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.custom-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 12vh !important;

  .btn {
    background-color: #1575C6 !important;
    padding: 12px 20px !important;
    border-color: #1565D8 !important;
  }
}