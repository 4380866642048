.analytics-dashboard-container {
    width: 100%;
    min-height: 85vh;
    padding: 15px;
}

// Row and Column Container function
.four-column-row-main-container {
    width: 100%;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.three-column-row-main-container {
    width: 100%;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.two-column-row-main-container {
    width: 100%;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.one-column-row-main-container {
    width: 100%;
    height: 350px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.four-column-row-column {
    width: 24%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #546E7A;
        border-bottom: 1px solid #CCCCCC;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        .title {
            font-size: 20px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 5px;
        }
    }

    &__body {
        width: 100%;

        .data {
            font-size: 40px;
            font-weight: bold;
            color: #0C6980;
            text-align: center;
        }
    }
}

.three-column-row-column {
    width: 32%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #546E7A;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        // border-bottom: 1px solid #CCCCCC;

        .title {
            font-size: 20px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 5px;
        }
    }
}

.two-column-row-column {
    width: 49%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        color: #546E7A;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        // border-bottom: 1px solid #CCCCCC;

        .title {
            font-size: 20px;
            text-align: left;
        }
    }
}

.one-column-row-column {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header {
        // height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
        color: #0C6980;
        border-bottom: 1px solid #CCCCCC;

        .title {
            font-size: 20px;
        }
    }

    &__body {
        height: 470px;
        width: 100%;
    }
}

.action,
.one-column-row-column-header-action {
    display: flex;
    justify-content: flex-end;

    .btn {
        padding: 6px 10px;
        display: flex;
        align-items: center;
        font-weight: 600 !important;

        &.query-btn{
            svg {
                width: 12px;
                height: 16px;
                margin-left: 5px;
            
            }
        }

        svg {
            width: 16px;
            height: 16px;        
        }

        margin-right: 5px;

        &:hover {
            background-color: #0C6980 !important;
            color: #FFFFFF !important;
            border-color: #0C6980 !important;
        }
    }
}