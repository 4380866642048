.chart-in-chat {

    &__chart {
        position: relative;

        .hover-text {
            position: absolute;
            z-index: 8;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 1;
            background-color: #0000004f;
            color: #fff;
            opacity: 0;
            transition: all 0.3s ease;
        }

        &:hover{
            .hover-text{
                opacity: 1;
                cursor: pointer;
            }
        }
    }
}