.banner {
    margin-bottom: 32px;
    h1 {
        color: #111827;
        font-size: 24px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.24px;
    }
}

.blogs-link-section {
    padding: 20px 40px 20px 20px;
    border-radius: 16px;
    background: linear-gradient(90deg, #0C6980 0%, #008EBC 100%);
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 20px;

    p {
        // font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 0;
        color: white;


        a {
            color: white;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-decoration-line: underline;
            display: inline-block;
            cursor: pointer;
            // margin-left: 6px;
        }
    }

    .close-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        color: white;
        padding: 0 !important;
        width: 20px;
        height: 20px;
        svg{
            width: 100%;
            height: 100%;
        }

        &:hover {
            color: white;
        }
    }

}