@import "../../../stylesheets/common-style.scss";
@import "../../../stylesheets/responsive.scss";


.auth-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &__left {
        position: relative;
        background-color: #0c6980; //for safer side
        background-repeat: no-repeat;
        background-position: inherit;
        background-size: inherit;
        width: 100%;
        height: calc(50vh + 100px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 100px 30px 60px;

        @media #{$mobile_device} {
            height: 300px;
        }

        @media screen and (min-width: 1000px) {
            width: calc(100% - 800px);
            height: calc(100vh + 100px);

        }
        @media screen and (min-width: 1200px) {
            // width: calc(100% - 1100px);
        }
        .brand-logo{
            position: absolute !important;
        }
    }

    .welcome-title {
        color: white;
        font-weight: 700;
        font-size: 28px;

        @media screen and (min-width: 760px) {
            font-size: 40px;
        }
    }

    &__content {
        color: white;

    }

    &__right {
        width: 100%;
        padding: 30px 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        @media screen and (min-width: 1000px) {
            width: 800px;
        }
        @media screen and (min-width: 1200px) {
            // width: 1100px;
        }
        form {
            width: 100% !important;
            @media screen and (min-width: 1000px) {
                width: 700px !important;
            }
        }
        .divider{
            width: 280px;

            @media screen and (min-width: 1000px) {
                width: 700px;
            }
        }

        .row{
            padding: 0 !important;
        }

        .form-password-toggle{
            padding: 0 !important;
        }
    }
}

.spinner-container{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #44ace4;
}

.resgister-title{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}