@import "../../stylesheets/variables.scss";
@import "../../stylesheets/responsive.scss";
@import "../../stylesheets/common-style.scss";


.loginEntryContainer {
    @media #{$mobile_device} {
        display: none;
    }
}

.mobileDisplayPageContainer {
    display: none;

    @media #{$mobile_device} {
        display: block;
        text-align: center;
    }
}

.appMobileLogo {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
}


.login-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &__left {
        position: relative;
        background-color: #0c6980; //for safer side
        background-repeat: no-repeat;
        background-position: inherit;
        background-size: inherit;
        width: 100%;
        height: 40vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 100px 30px 60px;

        @media screen and (min-width: 1000px) {
            width: calc(100% - 800px);
            height: 100%;

        }

        @media screen and (min-width: 1200px) {
            width: 65%;
        }

        .brand-logo {
            position: absolute !important;
        }
    }

    .welcome-title {
        color: white;
        font-weight: 700;
        font-size: 28px;

        @media screen and (min-width: 760px) {
            font-size: 40px;
        }
    }

    &__content {
        color: white;
        
        @media screen and (min-width: 760px) {
            margin-bottom: 4rem;
        }
        p {
            font-weight: 600;
            line-height: 1.3;
            font-size: 1.25rem;
        }
    }

    &__right {
        width: 100%;
        padding-top: 2rem;
        padding-inline: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: 1000px) {
            width: 800px;
        }

        @media screen and (min-width: 1200px) {
            width: 35%;
        }

        form {
            width: 100%;
        }

        .login-form {
            max-width: 600px;
        }
    }
}

.booking-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    border: 1px solid #44ace4 !important;
    background-color: transparent;
    padding: 0.786rem 1.5rem 0.786rem 54px;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
    color: #44ace4;
    font-weight: 500;
    position: relative;
    width: max-content;
    margin-inline: auto;
    height: 44px;
    overflow: hidden;
    transition: all 0.3s ease;

    .icon {
        position: absolute;
        background-color: #44ace4;
        border-radius: 50%;
        height: 44px;
        width: 44px;
        color: #ffffff;
        left: -1px;
        top: -1px;
        // bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #44ace4 !important;
    }

    svg {
        margin-inline: 5px;
    }


    &:hover {
        background-color: #44ace4;
        color: #ffffff;
    }

}

.spinner-container {
    color: #44ace4;
}

.testimonial-wrapper {
    width: 100%;
    display: none;

    @media screen and (min-width: 760px) {
        display: block;
    }

    &__title {
        font-size: 1.75rem;
        font-weight: 700;
        color: #ffffff;
    }
}