.plan-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
    padding: 20px 25px;
    border-radius: 16px;
    flex: 1 1 calc(25% - 15px);
    margin-right: 15px;
    margin-bottom: 15px;


    &__head {
        margin-bottom: 3.5rem;
    }

    &__price {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-bottom: 2rem;

        .rate {
            font-size: 32px;
            display: inline-block;
            line-height: 1;
            font-weight: 600;
            color: black;
        }

        .duration {
            font-size: 16px;
            display: inline-block;
            line-height: 1.3;
            color: black;
        }
    }

    &__text {
        display: block;
        // color: black;
        font-weight: 600;
        font-size: 14px;
        line-height: 1;
        margin-bottom: 1rem;
    }

    .btn-primary{
        border-color: #000 !important;
        background-color: #000 !important;

        &:hover{
            box-shadow: 0 8px 25px -8px #000 !important;
        }
    }

    .btn-outline-primary{
        border-color: #000 !important;
        color: #000 !important;
    }

    &__btn-wrapper {
        margin-bottom: 1rem;

        .btn {
            width: 100%;
            font-weight: 600 !important;
        }
    }

    &__offerings {
        padding-top: 20px;
        border-top: 1px solid #0c698042;

        ul {
            list-style: circle;
            margin-left: 20px;
            color: black;
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
            // gap: 5px;
            // column-gap: 25px;
        }

        li {
            font-size: 12px;
            // display: inline-block;
        }
    }

    &:hover {

        .btn-primary{
            background-color: #fff !important;
            color: #000 !important;
            box-shadow: 0 8px 25px -8px #000 !important;
        }
    }

    &.active-plan{
        // border-color: #0C6980;
    }

    &__title {
        font-size: 18px;
        color: #000;
        font-weight: 800;

    }
}

.coupon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    .coupon-btn {
        padding-top: 0;
    }

    .apply-btn {
        padding: 5px 10px;
        font-size: 12px;

        svg,
        span {
            width: 20px !important;
            height: 20px !important;
        }
    }
}