.google-sign-in-button {
    background-color: #4285F4; /* Google brand blue */
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
    outline: none;
    font-weight: bold;
    font-size: 16px;
}

.google-sign-in-button:hover {
    background-color: #357ae8; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly scale up */
}

.google-sign-in-button:active {
    background-color: #3367d6; /* Even darker blue when active */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}

.google-sign-in-button img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

.google-sign-in-button span {
    padding-top: 2px;
}
