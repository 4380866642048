@import "stylesheets/variables.scss";
@import "stylesheets/responsive.scss";
@import "stylesheets/common-style.scss";

body {
  height: 100%;
  margin: 0;
  padding: 0 !important;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

.vh-85 {
  height: 85vh;
  min-height: 85vh;
}

.padding_0 {
  padding: 0;
}

.main-container {
  height: 100%;
  background: white;
}

.full-screen {
  height: 100%;
  width: 100;
}

/* Additional features for fuild container */
.container-fluid {
  // padding-left: 10px;
  // padding-right: 10px;
}

/* Section header details */
.page-header {
  height: 5vh;
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid #B0BEC5;
  margin-top: 1.3%;
  margin-bottom: 1%;
}

.page-header>.tab-container {
  width: 75%;
  height: 100%;
  float: left;
}

.page-header>.search-container {
  width: 25%;
  min-width: 200px;
  height: 100%;
  float: left;
  padding-top: 5px;
}

.page-header>.tab-container>.section-head {
  font-size: 17px;
  padding-top: 18px;
  font-weight: bold;
}

.page-header>.search-container>.search-area {
  height: 37px;
  width: 100%;
  border: 1px solid hsl(0deg 0% 80%);
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
  }

}

.page-header>.search-container>.search-area>.header-search {
  width: 100%;
  height: 100%;
  outline: none;
  border: 0px;
  padding-inline: 5px 50px;
}

.page-container {
  width: 100%;
  height: calc(100vh - 5.35rem);
  max-height: calc(100vh - 5.35rem);
  min-height: 500px;
  background: #FFF;
  overflow-y: scroll;
}

.page-container-non-scroll {
  width: 100%;
  height: 85vh;
  min-height: 500px;
  background: #FFF;
}

.common-page-card {
  box-shadow: $box-shadow-light;
  border-radius: 5px;
}

.full-page-container {
  width: 100%;
  height: 93vh;
  max-height: 93vh;
  min-height: 500px;
  padding: 0;
}

.inline-left-align-content {
  padding: 10px;
  /* display: flex; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scroll-bar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.sipnner-container {
  text-align: center;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

select {
  /* Set the background color */
  background-color: #f4f4f4;
  /* Add padding to give some spacing */
  padding: 8px;
  /* Set the border and border radius */
  border: 1px solid #ccc;
  border-radius: 4px;
  /* Set the font size and font color */
  font-size: 16px;
  color: #333;
  /* Remove the default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Style the arrow icon (you can use an image or font icon here) */
select::after {
  content: '\25BC';
  /* Unicode Down Arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #555;
  pointer-events: none;
}

.modal-body {
  padding: 0.8rem 0.8rem;
}

.page-sipnner-container {
  padding-top: 100px;
  text-align: center;
}

.page-spinner-text {
  font-size: 16px;
  margin-top: 20px;
  color: $primaryColor;
  font-weight: bold;
}

.pointer-cursor {
  cursor: pointer;
}

.hidden {
  display: none;
}

.success {
  // background: #4CAF50;
}

.bold-text {
  font-weight: bold;
}

.center-text {
  text-align: center;
}

.strikethrough {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.crossed-text-style {
  color: #EF5350;
  font-style: italic;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #78909C;
  font-weight: 200;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #78909C;
  font-weight: 200;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #78909C;
  font-weight: 200;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
  height: 45px !important;
}

.app-content{
  padding-inline: 0 !important;
  @media screen and (min-width: 760px) {
    padding-inline: 2rem !important;
  }
}

.mobile-hidden{
  @media #{$mobile_device} {
    display: none;
  }
}