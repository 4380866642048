.chatBotContainer {

    .top-panel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    .tab-Wrapper{
        background-color: #f3f2f7;
        overflow: hidden;
        width: max-content;
        .nav-tabs{
            margin-bottom: 0 !important;
        }
        .nav-item{
            &:last-child{
                .nav-link{
                    border-right: 0;
                }
            }
        }
        .nav-link {
            padding: 11px 20px;
            font-weight: 500;
            color: #6e6b7b;
            font-size: 16px !important;
            border-right: 1px solid #d8d6de;

            &.active {
                background-color: #0C6980;
                color: white !important;

                &::after {
                    display: none !important;
                }
            }

            &.disabled{
                cursor: not-allowed !important;
                pointer-events: unset !important;
            }
        }
    }

}

.bot-modal {
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0 !important;
    max-width: 350px !important;
    .modal-header{
        justify-content: center !important;
        h5{
            font-size: 24px;
        }
    }
    .modal-body{
        padding: 2rem 1.5rem !important;
    }
    .bot-btn {
        background-color: transparent;
        border: unset;
        padding: 15px 20px;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        font-size: 14px;
        color: white;
        cursor: pointer;

        svg{
            margin-right: 10px;
            width: 20px;
            height: 20px;
            color: white;
        }

        &.web{
            background-color: #0C6980;
        }

        &.wtsapp{
            background-color: #26c943;
        }
        &.tele{
            background-color: #23a0dc;
          
        }

        &:hover{
            box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.2);
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.65; // Adjust the opacity to indicate the button is disabled
        }

        &__wrapper{
            text-align: left;
            width: 140px;
        }
    }
}