.chatBot-profile {
    padding: 20px;

    &__tabs {
        background-color: #f3f2f7;
        overflow: hidden;
        width: max-content;
        margin-bottom: 2rem;

        .nav-link {
            padding: 16px 20px;
            font-weight: 500;
            color: #6e6b7b;
            font-size: 16px !important;
            border-right: 1px solid #d8d6de;

            &.active {
                background-color: #0C6980;
                color: white !important;

                &::after {
                    display: none !important;
                }
            }
        }
    }
}

//profileform
.profileform {
    display: flex;
    border: 1px solid #d8d6de;
    border-radius: .357rem;
    overflow: hidden;
    overflow-y: auto;

    &.showUpload {
        .upload-wrapper {
            width: 30%;
            background-color: #FAFAFA;
            padding: 20px;
            min-height: 65vh;
        }

        .form-wrapper {
            width: 70%;
        }
    }

}

.form-wrapper {
    padding: 2rem 2rem 20px;
    width: 100%;

    form {
        width: 100% !important;
    }

    .form-group {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    label {
        width: 100px;
        margin-right: 10px;
        font-size: 14px;
    }

    input,
    select {
        width: calc(100% - 110px);
    }

    .readOnlyClass {
        pointer-events: none;
    }

    .form-control[readonly] {
        background-color: #fff !important;
        opacity: 1 !important;
        pointer-events: none !important;
    }

    small {
        display: block;
        width: 100%;
        margin-left: 110px;
    }

    .color-picker {
        input {
            cursor: pointer;
        }
    }

    .btn {
        span {
            width: 15px !important;
            height: 15px !important;
        }
    }
}

.confirmation-fields {
    input {
        width: 1.285rem !important;
        height: 1.285rem !important;
    }

    label {
        width: 100% !important;
    }
}

.custome-btn-group {
    justify-content: center;
    margin-top: 2rem;

    .btn {
        flex: none;
    }
}

//script-wrapper

.script-wrapper {
    // background-color: #000000;
    border-radius: 5px;
    border: 12px solid #d3d3d3;
    border-top: 0;
    margin-top: 3rem;

    // margin-bottom: 2rem;
    .language-javascript {
        margin-block: 0 !important;
    }

    &__top {
        background-color: #d3d3d3;
        min-height: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 6px;
    }

    pre {
        margin-block: 0 !important;
    }

    .copy-btn {
        background-color: transparent;
        border: none;
        outline: none;

        &__title {
            font-size: 14px;
            font-weight: 500;
            display: inline-block;
        }

        .icon {
            display: inline-block;
            margin-right: 6px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}

.upload-pannel {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    &__top {
        height: 50px;
        border-bottom: 1px solid #0C6980;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #0C6980;
    }

    &__body {
        min-height: calc(100% - 130px);
        padding: 10px 0;
    }

    &__bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        height: 80px;
    }

    .custom-input {
        position: relative;
        width: 49%;
        overflow: hidden;
        cursor: pointer;
        z-index: 1;

        input {
            position: absolute;
            // width: 100%;
            box-sizing: border-box;
            left: -100%;
            right: -100%;
            cursor: pointer;
            height: 100%;
            opacity: 0;

        }

        .btn {
            width: 100%;
        }
    }

    .upload-btn {
        width: 100%;
        margin-bottom: 6px;

        .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            display: inline-block;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    .btn-success {
        width: 100%;
        margin-bottom: 6px;

    }

    .url-btn {
        width: 49%;
    }
}

.min-vh {
    min-height: 75vh;
}

.upload-items {
    justify-content: space-between;
    margin-bottom: 10px;
    border-radius: 6px !important;
    overflow: hidden;

    &:first-child {
        margin-top: 16px;
    }

    .file-preview {
        width: 28px;
        height: 28px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .close-btn {
        width: 30px;
        height: 30px;
        padding: 0 !important;
        background-color: red;
    }

    .file-data {
        width: calc(100% - 72px);
        padding-right: 5px;

        .file-name {
            font-size: 16px;
        }

        .file-size {
            font-size: 12px;
            line-height: 1;
        }
    }
}

.empty-state-message {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px;
    font-weight: 700;
}

.bot-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    padding: 40px 20px;

    .bot-chat {
        height: 100%;
        width: 300px;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0 3px 8px #0000003d;

        &__top {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 65px;
        }

        .comment {
            color: #f1f1f1;
            background-color: rgba(0, 0, 0, 0.1);
            padding: 6px 16px;
            border-radius: 6px;
            display: inline-block;

            svg {
                margin-right: 5px;
            }
        }

        .help {
            color: #f1f1f1;

            svg {
                margin-right: 15px;
            }
        }

        &__body {
            background-color: white;
            padding: 15px 10px;
            height: calc(100% - 145px);
        }

        .bot-msg {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;

            &__msg {
                border: 1px solid #E0F2F1;
                padding: 6px;
                border-radius: 6px 6px 6px 0;
                width: 70%;
            }
        }

        .user-msg {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;

            span {
                display: inline-block;
                padding: 6px;
                border-radius: 6px 0 6px 6px;
                width: 70%;
            }

        }

        &__bottom {
            border-top: 1px solid rgb(204, 204, 204);
            padding: 10px;
            height: 80px;

            &__attach {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .input {
            height: 3vh;
            width: 100%;
            display: flex;
            justify-content: space-between;
            color: rgb(204, 204, 204);
            align-items: center;
            margin-bottom: 10px;
        }

        .clip {
            color: #90a4ae;

            svg {
                margin-right: 8px;
                width: 16px;
                height: 16px;
            }
        }

        .powered-by {
            display: flex;
            align-items: center;

            img {
                width: 20px;
                height: 20px;
                margin-left: 5px;
            }
        }
    }
}

.loader-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #0000003d;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;

    span {
        width: 100px !important;
        height: 100px !important;
    }
}

.bot-icon {
    width: 300px;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    &__logo {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 30px;
            height: 30px;
            margin: auto;

        }
    }
}