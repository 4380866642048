.main-bsp {
    padding: 20px;

    @media screen and (min-width: 760px) {
        height: 85vh;
    }


}

.fsa {
    .tab-container {
        width: calc(100% - 410px);
    }

    .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 400px;
    }

    .custom-btn {
        position: relative;

        &:hover {
            span {
                opacity: 1;
            }
        }

        span {
            position: absolute;
            font-size: 10px;
            bottom: -26px;
            background-color: white;
            display: inline-block;
            padding: 6px 10px;
            border-radius: 6px;
            width: 80px;
            border: 1px solid rgba(34, 41, 47, 0.1);
            left: 0;
            right: 0;
            opacity: 0;
        }
    }
}



.dropdown-item {
    font-size: 20px !important;
    width: 100% !important;

    img {
        width: 26px !important;
        height: 26px !important;
        margin-right: 10px;
    }

    .country-name {
        display: inline-block;
        font-size: 20px;
    }
}

.error-msg {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    height: 30vh;
}

.main-banks {

    h2 {
        font-size: 14px;
        font-weight: 600;
        color: #111827;
    }

    .banks_wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding-block: 24px;
        border-bottom: 1px solid #ECEDF3;

        @media screen and (min-width: 760px) {
            grid-template-columns: repeat(4, 1fr);

        }
    }

    &__tile {
        // width: ;
        // max-width: 100px;
        background-color: #F9FAFF;
        padding: 16px;
        border-radius: 8px;
        font-size: 14px;
        display: flex;
        line-height: 20px;
        color: #111827;
        font-weight: 500;
        // flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid #fff;

        &:hover {
            box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
            border-color: rgba(34, 41, 47, 0.3);
        }

        img {
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin-right: 6px;
        }
    }

    .bank-name {
        display: block;
        font-weight: 700;
        text-align: center;
    }
}

//other-bank-list
.other-bank-list {
    padding-block: 24px;

    .list-group-item {
        background-color: #fff !important;
    }

    .list-item {
        cursor: pointer;
        font-size: 15px;

        svg {
            width: 25px;
            height: 15px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1.25rem;
            fill: #6e6b7b;
        }

        &:hover {
            background-color: #f9f9f9 !important;
        }
    }
}
.letters{
    margin-bottom: 0;
    margin-top: 20px;
    font-size: 12px !important;
    color: #6B7280 !important;
}

.bank-icon {
    width: 40px;
    height: 50px;
    display: inline-block;
    margin-right: 10px;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.search-bank {
    h2 {
        font-size: 14px;
        font-weight: 600;
        color: #111827;
    }

    .mb-3 {
        position: relative;
    }

    .input-icon {
        position: absolute;
        top: 50%;
        right: 13px;
        transform: translateY(-50%);
    }
}

//bank-sidebar

.bank-sidebar {
    height: 100vh;
    z-index: 1;
    border-right: 0 !important;
    width: 200px !important;
    border-right: 1px solid #ddd !important;

    // padding-top: 50px;
    .css-dip3t8 {
        background-color: #fff !important;
        padding-block: 20px;

        .ps-menu-button {
            font-size: 16px;

            &:hover {
                color: #7169E8 !important;
            }
        }

        .ps-submenu-content {
            .ps-menu-button {
                font-size: 16px;
                color: #7169E8 !important;
            }
        }
    }
}


//upload
.bsp-upload {
    min-height: 50vh;
    align-items: center;
    justify-content: center;
    // cursor: pointer;
    position: relative;

    &.wd-processbar{
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background-color: #ffffff87;
        }
    }
    
    .card-body {
        flex: unset;
    }

    .page-container {
        min-height: 200px;
        height: 500px;
    }

    .back-btn {
        position: absolute;
        top: 12px;
        left: 10px;
        border: none;
        outline: none;
        color: #95939e;
        cursor: pointer;
        background: transparent;
    }


    .upload-icon{
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .upload-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: #111827;
        margin-top: 24px;
    }

    .custome-note {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        margin-top: 12px;
        color: #111827;

        &.limit {
            border-radius: 8px;
            border: 1px solid #BC4C1C;
            background: #FFE8DE;
            color: #BC4C1C;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            padding: 12px;
        }
    }

    .upload-btn {
        padding: 8px 20px !important;
        background-color: #1575C6 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        border-radius: 12px !important;
        border-color: #1575C6 !important;

        &.disabled {
            cursor: not-allowed !important;
        }
    }
}

.custom-dropzone {
    border-radius: 8px;
    border: 1px dashed #1575C6;
    background: #F9FAFF;
    padding: 34px 20px;
    margin-top: 20px;
    cursor: pointer;

    svg {
        color: #0C6980;
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    small {
        color: #0C6980;
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
    }

    .preview-cards {
        border: 1px solid #0C6980;
        border-radius: 12px;
        min-height: 160px;
        background-color: white !important;
        position: relative;
        flex-direction: column;
        justify-content: center;


        .file-preview {
            width: 30px;
            height: 30px;
            margin-right: 0;
            margin-bottom: 20px;
        }

        .file-data {
            font-size: 12px;
            line-height: 1.3;
            color: #111827;
            font-weight: 600;
            text-align: center;
        }

        .close-btn {
            position: absolute !important;
            top: 10px;
            right: 10px;
            background-color: transparent !important;
            border: none !important;
            height: 20px;
            width: 20px;
            padding: 0 !important;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &.has-files {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 20px;

        .custom-dropzone__field {
            border: 1px solid #0C6980;
            padding: 20px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            div {
                flex-direction: column;

                svg {
                    margin-bottom: 20px;
                }
            }
        }

        small{
            text-align: center;
            width: 100%;
            margin-top: 8px;
            line-height: 1.4;
        }
    }

    &.limit-exceeded {
        .custom-dropzone__field {
            display: none;
        }
    }
}

.bank-info {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        margin-right: 15px;
        border-radius: 10px;
    }

    h2 {
        font-size: 20px;
        margin-bottom: 0;
    }
}

.document-process {
    z-index: 2;
    position: relative;

    .bank-details {
        img {
            width: 25px;
            height: 25px;
        }

        h2 {
            font-size: 20px;
            line-height: 1;
            margin-bottom: 0;
            margin-inline: 0.5rem;
        }
    }

    &__title {
        font-size: 2rem;
        font-weight: 600;
        margin-bottom: 3rem;

        &.success {
            color: #4CAF50;
        }
    }

    &__notification {
        text-align: center;
    }

    .document-progressbar {
        .bg-primary {
            background-color: #0082AC !important;
            color: #0082AC !important;
        }
    }

    .process-info {
        margin-top: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h5 {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}

.completed-text {
    color: #4CAF50;
    display: inline-block;
    font-size: 19px;
    line-height: 1;
}

.card-title {
    &.success {
        color: #4CAF50;
    }
}


//document-preview-modal
.document-preview-modal {
    max-width: 90vw !important;
    margin-top: 8rem !important;

    .btn-close {
        transform: none !important;
    }

    .modal-body {
        max-height: 80vh !important;
        overflow-y: auto !important;
    }
}

//balance-sheet-table
.balance-sheet-table {
    width: 100%;
    border-collapse: collapse;

    th {
        text-align: left;
        vertical-align: middle !important;
        padding: 8px;

        &:first-child {
            width: 70%;
        }

        &:not(:first-child) {
            text-align: center;
            width: 10%;
        }
    }

    td {
        padding: 8px;

        &:first-child {
            width: 70%;
        }

        &:not(:first-child) {
            text-align: center;
            width: 10%;
        }
    }

    .category-title {
        font-size: 18px;
        padding-block: 16px !important;
    }

    .total-row {
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px
    }

}



.upload-popup {
    top: 10%;
}

.section-head {
    display: flex;
    align-items: center;
}

.edit-btn {
    background-color: transparent;
    border: unset;
    outline: unset;
    cursor: pointer;
    width: 15px;
    height: 15px;
    padding: 0;
    line-height: 1;
    margin-left: 10px;
    font-size: 14px;
    color: #7169E8;

    svg {
        width: 100%;
        height: 100%;
    }
}

.input-wrapper {
    width: 300px;
}

.analysis-table {

    // height: 100%;
    &__banner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        .dropdown-toggle {
            background-color: #1575C6 !important;
            padding: 8px 20px !important;
            font-size: 16px !important;

        }

    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        text-transform: capitalize !important;
        line-height: 28px;
        color: #111827;
        letter-spacing: 0.18px;
        margin-bottom: 4px !important;
    }

    p {
        font-size: 14px;
        font-style: normal;
        color: #4B5563;
        font-weight: 400;
        line-height: 20px;
    }

    &__main {
        display: flex;
        border: 1px solid rgba(34, 41, 47, 0.125);
        overflow: hidden;
        border-radius: 10px;
    }

    .custom-analysis-bar {
        display: flex;
        flex-direction: column;
        border-right: 1px solid rgba(34, 41, 47, 0.125);
        width: 300px;


        .custom-btn {
            border-bottom: 1px solid rgba(34, 41, 47, 0.125);
            padding: 14px 20px;
            font-size: 16px;
            cursor: pointer;

            &.active {
                background-color: #7169E8;
                color: white;
            }

            &:hover {
                background-color: #7169E8;
                color: white;
            }
        }
    }

    &__wrapper {
        height: 60vh;
        border: unset;
        overflow: hidden;
        position: relative;
        // width: calc(100% - 300px);
        width: 100%;


        table {
            // border: unset !important;

            th {
                font-size: 14px;
                font-weight: 600 !important;
                text-transform: capitalize !important;
                color: #111827 !important;
                background-color: #F9FAFF !important;
            }

            td {
                font-size: 14px;
                font-weight: 500 !important;
                text-transform: capitalize !important;
                color: #111827 !important;
            }
        }
    }

    &__tabcontent {
        padding-bottom: 40px;
    }

    &__tabs-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__tabs {
        width: 105vw;
        flex-wrap: nowrap;
        background-color: #f3f2f7;
        margin-bottom: 0;
        border-radius: 0;
        z-index: 2;
    }

    &__tabpane {
        overflow: auto;
        height: 60vh;
        padding-bottom: 40px;

        &::-webkit-scrollbar {
            // height: 4px;
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 6px;
        }

        &::-webkit-scrollbar-track:hover {
            background-color: #f0f0f0;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #555;
        }

        &::-webkit-scrollbar-track {
            background-color: #e0e0e0;
        }

        &::-webkit-scrollbar-corner {
            background-color: #fff;
        }
    }

    .nav-item {}

    .nav-link {
        padding: 16px 20px;

        &.active {
            background-color: #0C6980;
            color: white;
            // padding: 16px 20px;

            &::after {
                display: none;
            }
        }
    }
}

.dropdown-menu {
    min-width: 175px;

    // width: 100%;
    button.dropdown-item {
        font-size: 14px !important;
        color: #111827 !important;
    }
}

.bank-dropdown__menu {
    // min-width: 100px; 
    // max-width: 150px;
    left: -30px !important;

    button.dropdown-item {
        .country-name {
            word-break: break-all;
        }

    }

}

.data-table {
    &__head-row {
        ul {
            display: flex;
            align-items: center;
            list-style: none;

            li {
                background-color: #f3f2f7;
                text-transform: uppercase;
                font-size: 0.857rem;
                letter-spacing: 0.5px;
                border: 1px solid #ebe9f1;
                color: #6e6b7b;
                font-weight: 700;
                padding: 10px 28px;
            }
        }
    }

    &__body {
        display: flex;
    }

    .row-columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .cells {
        padding: 0.72rem 2rem;
        border: 1px solid #ebe9f1;
        color: #6e6b7b;
        list-style: none;

    }
}

.analysis-dropdown {
    display: flex;
    justify-content: flex-end;
}

.bank-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    border-bottom: 1px solid #ECEDF3;

    &__actions {
        display: flex;
        align-items: center;
    }

    .bank-stepper {
        min-width: 400px;

        .Mui-completed {
            color: #0C6980;
        }

        .Mui-active {
            color: #0C6980;
        }
    }

    .bank-search {
        max-width: 170px;

        .mb-3 {
            margin-bottom: 0 !important;
            position: relative;
        }

        .input-icon {
            position: absolute;
            top: 50%;
            left: 13px;
            transform: translateY(-50%);
            color: #D2D5DB;
        }

        .form-control {
            padding-left: 40px;
            border-color: #D2D5DB !important;
            border-radius: 8px;

            &::placeholder {
                color: #D2D5DB;
            }
        }
    }

    .country-dropdown {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 20px;

        .dropdown-toggle {
            min-width: 170px;
            font-size: 14px;
            padding-block: 8px;
            color: #111827;
            font-weight: 400;
            border-radius: 8px !important;

            border: 1px solid #D2D5DB !important;
            background: #FFF;


            img {
                width: 20px !important;
                height: 20px !important;
                margin-right: 10px;
            }
        }
    }

    .bank-dropdown {


        .dropdown {
            display: flex;

        }

        .dropdown-toggle {
            height: 40px;
            min-width: 160px;
            max-width: 240px;
            padding: 8px 12px;
            border-radius: 8px;
            border: 1px solid #ECEDF3 !important;
            background: #F9FAFF;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: #111827;

            &::after {
                color: #1575C6;
            }

            img {
                width: 20px !important;
                height: 20px !important;
                margin-right: 10px;
            }
        }
    }
}

.backIcon {
    color: #808080 !important;
    display: flex;
    align-items: center;
    padding-left: 0 !important;
    margin-bottom: 26px;

    svg {
        width: 20px;
        height: 20px;
    }

    span {
        margin-left: 6px;
        display: inline-block;
        font-size: 12px;
    }
}

.bottom-btn-wrapper {
    margin-top: 32px;

    .btn {
        font-size: 16px !important;
        font-weight: 600;
        padding: 12px 46px !important;
        line-height: 24px;
        border-radius: 12px !important;
    }

    .btn-outline-primary{
        color: #1575C6;
        border-color: #1575C6 !important;
        margin-right: 20px;
        &:hover{
            color: white !important;
            background-color: #1575C6 !important;
        }
    }
    .btn-primary{
        border-color: #1575C6 !important;
        background-color: #1575C6 !important;

        &:hover{
            color: #1575C6 !important;
            background-color: white !important;
        }
    }
}