.news-block {
    padding-bottom: 32px;

    &__subtitle {
        color: #111827;
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.18px;
        margin-bottom: 0;
    }

    &__content {
        color: #6B7280;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        display: inline-block;
    }
}

.news-card {
    border-bottom: 1px solid #ECEDF3;

    &__link {
        display: flex;
        align-items: center;
        padding-block: 16px;
    }

    &__img {
        width: 166px;
        height: 116px;
        border-radius: 12px;
        overflow: hidden;
        margin-right: 20px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__title {
        color: #111827;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        margin-bottom: 5px;
    }

    &__content {
        width: calc(100% - 186px);
        p {
            overflow: hidden;
            color:  #374151;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.12px;
            margin-bottom: 0;
        }
    }
}