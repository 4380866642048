.chatbot-analytics {
    padding: 20px;
    background-color: #FAFAFA;
    border-radius: 6px;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 12px; /* Width of the entire scrollbar */
    } 
    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Color of the track */
        border-radius: 10px; /* Rounded corners for the track */
    }
    &::-webkit-scrollbar-thumb {
        background-color: #888; /* Color of the scroll handle */
        border-radius: 10px; /* Rounded corners for the handle */
        border: 3px solid #f1f1f1; /* Padding around the handle */
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* Darker color when hovered */
    }

    &__top {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin-bottom: 30px;
    }

    &__middle {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-bottom: 30px;

    }

    &__table {
        background-color: #fff;
        padding: 15px;
        border-radius: 12px;
        box-shadow: 0 3px 8px #0000003d;
        h2{
            margin-bottom: 20px;
        }
    }
}

.count-card {
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 3px 8px #0000003d;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__count {
        display: block;
        font-size: 80px;
        color: #0C6980;
        text-align: center;
        margin-bottom: 20px;
        line-height: 1.2;
    }

    &__title {
        display: block;
        font-size: 16px;
        text-align: center;
        margin-bottom: 20px;
    }
}