
  .aml-container {
    padding-top: 20px;
  }
  
  .summary-blocks {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
  }
  
  .summary-block {
    background-color: #ffffff;
    flex: 1;
    padding: 15px;
    text-align: center;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
  .summary-block h2 {
    font-size: 2rem;
    margin-bottom: 5px;
    color: #333;
  }
  
  .summary-block p {
    font-size: 1rem;
    color: #666;
  }
  
  .content-blocks {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .suspicious-activities-block {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
  .suspicious-activities-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .suspicious-activities-table th,
  .suspicious-activities-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .comparative-bar-graph {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  
  .full-width-graph {
    margin-top: 30px;
    margin-bottom: 5px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
  