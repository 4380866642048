@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets/responsive.scss";
@import "../../../stylesheets/common-style.scss";

.pageHeaderContainer{
    width: 100%;
    height: 45px;
    font-size: 17px;
    margin-bottom: 5px;
    padding-top: 15px;
    padding-bottom: 7px;
    color: #546E7A;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #B0BEC5;
}
.backIcon{
    margin-right: 10px;
    cursor: pointer;
}
.pageHeaderContainer .headerBtn{
    padding-left: 15px;
    padding-right: 15px;
    height: 30px;
    border-radius: 5px;
    color: $primaryColor;
    background: $white;
    border: 1px solid $primaryColor;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.head-info-indicators{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 5px;
    height: 100%;
    // border: 1px solid $light-grey;
}
.head-info-indicators .data-block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding-left: 5px;
    border-left: 2px solid $medium-grey;
}
.right-border{
    border-right: 2px solid $medium-grey;
}
.head-info-indicators .data-block .key{
    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    font-weight: 500;
}
.head-info-indicators .data-block .value{
    font-size: 16px;
    color: $dark-grey;
    padding-left: 15px;
    padding-right: 15px;
    text-align: right;
    font-weight: bold;
}