.source-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.source-button {
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Updated from center to flex-end */
    width: 100%;
    padding: 10px 20px;
    margin: 5px 0;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    color: #333;
    text-align: right; /* Ensures any child text elements are right-aligned */
}

.source-button i {
    margin-right: 8px; /* Adjust or remove based on your design preference */
}

.source-button:hover {
    border-color: #888;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
