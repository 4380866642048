.report-upload-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100px;
    background-color: #4CAF50; /* Green background */
    color: white;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.alert-icon{
    cursor: pointer;
}
.score-pannel{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;

    .separator{
        display: inline-block;
        font-size: 30px;
        margin-inline:8px ;
    }

    &__card{
        font-size: 16px;
        font-weight: 500;

        .score{
            font-weight: 800;
            display: inline-block;
            font-size: 20px;

        }

        .decision{
            font-weight: 800;
            display: inline-block;
            font-size: 20px;
            color: #4CAF50;
        }
    }
}