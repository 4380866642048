.report-upload-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100px;
    background-color: #4CAF50; /* Green background */
    color: white;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cashflow{
    .template-container-item{
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.file-col{
            cursor: default;
            padding: 20px;
        }
        
    }

    .template-container-items-list{
        grid-template-columns: repeat(4, 1fr)
    }
}

.cashflow-upoad-container{
    margin-top: 10px;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    .cashflow-upload{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .files-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .file-list{
        margin:10px 0;
        border-top: 1px solid #f0f0f0;

        li{
            background-color: white;
            padding: 5px 8px;
            border-bottom: 1px solid #f0f0f0;
            width: 350px;
            color: black;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
.files-section-title{
    display: block;
    font-weight: 800;
    font-size: 16px;
    text-align: center;
    color: black;
}

.company-selection-form{
    width: 700px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #f0f0f0;
    border-radius: 16px;
}

.cashflow-table{
    &__banner{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title{
        font-size: 28px;
        font-weight: 600;
        color: black;
    }
    p{
        margin-bottom: 0;
        font-weight: 500;
    }
}

.pagination-wrapper{
    display: flex;
    justify-content: right;
    margin-top: 15px;
}