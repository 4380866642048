/* TransactionInfo.css */
.transaction-container {
    background-color: #ffffff;
    padding-top: 5px;
  }
  
  .transaction-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .transaction-table-wrapper {
    max-width: 100%;
    overflow-x: auto;
    border: 1px solid #ddd;
  }
  
  .transaction-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .transaction-table thead {
    position: sticky;
    top: 0;
    background-color: #f8f8f8;
    z-index: 1;
  }
  
  .transaction-table th,
  .transaction-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    color: #333;
    white-space: nowrap;
  }
  
  .transaction-table th {
    background-color: #e6e6e6;
    font-weight: bold;
    color: #666;
  }
  
  .transaction-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .transaction-table tbody tr:last-child td {
    border-bottom: none;
  }
  