.main-container {
    display: flex;
    height: 85vh;
}

.left-panel {
    width: 20%;
    background-color: #FAFAFA;
    /* padding: 20px; */
    display: flex;
    flex-direction: column;
}

.header {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

.document-list {
    flex-grow: 1;
    overflow-y: auto;
    margin-bottom: 20px;
    list-style: none;
    padding: 0;
}

.document-list li {
    background-color: #FFFFFF;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
}

.upload-btn {
    padding: 10px 20px;
    background-color: #E0F2F1;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.right-panel {
    width: 80%;
    padding: 20px;
}

.chat-display {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #fff;
}

.message-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    outline: none;
    width: 100%;
}