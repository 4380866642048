.button-reset {
  background-color: #44ace4;
  /* Change the background color as desired */
  color: white;
  border: none;
  padding: 5px;
  margin: 140% 0px 0px 10px;
  /* Adjust the margin as needed */
  cursor: pointer;
  height: 30px;
  width: 60px;
  border-radius: 4px;
  /* Add rounded corners */
}

.button-reset:hover {
  background-color: #f44336;
  /* Change the background color on hover */
}

.transactions-preview-card {
  tbody {
    border: none;

  }

  tr {
    border: none;
  }

  th,
  td {
    border: none;
    padding-block: 6px !important;
  }
}

.payment-details {
  table {
    tbody {
      display: grid;
      grid-template-columns: auto auto;
    }


    tr {
      display: flex;

      th,
      td {
        width: 50%;
      }
    }
  }
}