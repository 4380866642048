@import "../../../../stylesheets/variables.scss";
@import "../../../../stylesheets/responsive.scss";
@import "../../../../stylesheets/common-style.scss";

.pageNameStyle {
    font-size: 18px;
    font-weight: 600;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    display: flex;
    color: $black;
}
.backIcon{
    margin-right: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}
.headerNavBtn{
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    margin-left: 20px;
    height: 30px;
    border-radius: 5px;
    color: $primaryColor;
    background: $white;
    border: 1px solid $primaryColor;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.headerNavBtn2{
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    margin-left: 10px;
    height: 30px;
    border-radius: 5px;
    color: $primaryColor;
    background: $white;
    border: 1px solid $primaryColor;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.header-navbar{
    box-shadow: none !important;
    border-bottom: 1px solid #0d6a8142;
}
.user-nav{
    margin-right: 0 !important;
    margin-left: 12px;
    

    .user-name{
        font-size: 14px;
        font-weight: 600 !important;
        margin-bottom: 0 !important;
        color: #111827 !important;
    }
}