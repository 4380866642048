.custom-tabs{
    &__tab{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 250px;
        span{
            width: 20px;
            height: 20px;
            display: inline-block;
            margin-left: 10px;
        }
        &:last-child{
            border-right: unset !important;
        }
    }
}