@import "./variables.scss";
@import "./responsive.scss";

.actionIcon{
    margin-right: 10px;
    cursor: pointer;
}
.actionIconSuffix{
    margin-right: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.titleBlockButton{
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    background: $primaryColor;
    font-size: 14px;
    color: white;
    margin-left: 10px;
    cursor: pointer;
}
.titleBlockButtonInvert{
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    background: $white;
    border: 1px solid $primaryColor;
    font-size: 14px;
    color: $primaryColor;
    margin-left: 10px;
    cursor: pointer;
}
.titleBlockButtonInvert:hover{
    background: $primaryColor;
    color: $white;
}
.labelBlockButton{
    // padding-left: 10px;
    // padding-right: 10px;
    // padding-top: 3px;
    // padding-bottom: 3px;
    // border-radius: 5px;
    // background: #2196F3;
    font-size: 16px;
    color: #2196F3;
    margin-left: 10px;
    // box-shadow: $button-box-shadow;
    cursor: pointer;
}
.labelExampleStyle{
    font-size: 12px;
    margin-left: 10px;
    font-style: italic;
}
.labelTextArea{
    margin-top: 15px;
    color: #546E7A;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bolder;
    width: 100%;
}
.labelTextArea:focus{
    outline: none;
}
.wordAnalysisContainer{
    position: absolute;
    bottom: 20px;
    height: 40px;
    color: $primaryColor;
    text-align: start;
    font-size: 15px;
    font-weight: bold;
    padding-left: 40px;
    padding-top: 10px;
}

.navbar-sticky .app-content{
    padding: calc(1rem + 6.45rem) 2rem 0 2rem !important;
    padding-bottom: 2.35rem !important;
}
.footer-fixed .content.app-content {
    padding-bottom: 1.35rem !important;
}
