.infoLayover {
	background-color: #272829;
	opacity: 0.4;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 9999;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.centerInfoLayover {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 9999;
	transform: translate(-50%, -50%);
}

.infoContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 504px;
	height: 313px;
	background-color: white;
	border-radius: 20px;
}

.imgContianer {
	width: 90px;
	height: 90px;
}

.infoHeader {
	margin: 20px 0px 10px 0px;
	color: #272829;
	font-size: 22px;
	font-weight: bold;
	font-family: "Nunito", sans-serif;
}

.infoPara {
	color: #5A5E66;
	font-size: 14px;
	margin: 0px 80px;
	text-align: center;
	font-family: "Nunito", sans-serif;
}