.response-action {
    margin-top: 5px;
    border-top: 1px solid #e9ecef;
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__cta {
        display: flex;
        justify-content: flex-start;
        position: relative;
        margin-bottom: 25px;

        .btn {
            padding: 6px 12px;
            font-size: 16px;
            font-weight: 700;
            margin-right: 5px;

            svg {
                width: 16px;
                height: 16px;
            }


        }

        .tooltip {
            position: absolute;
            bottom: -100%;
            left: -25px;
            background-color: rgba(0, 0, 0, 0.75);
            color: white;
            padding: 5px;
            border-radius: 4px;
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s;
            z-index: 1000;
            white-space: nowrap;
        }


        &:hover {
            .tooltip {
                visibility: visible !important;
                opacity: 1 !important;
            }
        }
    }

    &__feedback {
        .btn {
            padding: 6px 12px;
            font-size: 16px;
            font-weight: 700;
            margin-right: 5px;

            svg {
                width: 16px;
                height: 16px;
            }

            &.active {
                svg {
                    fill: #44ace4;
                }
            }
        }
    }
}