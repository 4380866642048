.custom-md-table-header{
    background-color: #f9f9f9;
    border-top: none;
    border-bottom: 1px solid #e9ecef;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
}

.custom-md-table-row{
    border-bottom: 1px solid #e9ecef;
    font-size: 15px;
    height: 30px;
    cursor: default;
}

.md-data-class{
    padding: 10px;
}
// .custom-md-table-row:hover{
//     background-color: #f9f9f9;
//     box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
// }

.custom-md-table{
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
}