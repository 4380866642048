@import '../../base/pages/authentication.scss';

.auth-wrapper .auth-footer-btn .btn {
  padding: 0.555rem 0.6rem !important;
}

.auth-wrapper .brand-logo {
  margin: 0 !important
}

.disabled-link {
  color: gray !important; /* Change the text color to gray */
  cursor: not-allowed !important; /* Change the cursor to 'not-allowed' */
  text-decoration: none !important; /* Remove underline */
  pointer-events: none !important; /* Disable pointer events, making it unclickable */
}
